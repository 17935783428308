import React, { Component } from 'react'
import BlackMagical from '../BlackMagical.jpg'
import BrownMagical from '../BrownMagical.jpg'
import WhiteMagical from '../WhiteMagical.jpg'
import Carousel from 'react-bootstrap/Carousel'



export class MagicalCarousel extends Component {
    render() {
        return (
            <div>
                

                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackMagical}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteMagical}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownMagical}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>










            </div>
        )
    }
}

export default MagicalCarousel
