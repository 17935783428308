import React, { Component } from 'react'
import BlackLady from '../BlackLady.jpg'
import BrownLady from '../BrownLady.jpg'
import WhiteLady from '../WhiteLady.jpg'
import Carousel from 'react-bootstrap/Carousel'
export class LadyCarousel extends Component {
    render() {
        return (
            <div>
                


                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackLady}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteLady}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownLady}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>





                
            </div>
        )
    }
}

export default LadyCarousel
