import React, { Component } from 'react'
import TrumpetP from '../trumpet.jpg';

import { Jumbotron } from 'react-bootstrap';


export class Trumpet extends Component {
    render() {
        return (
            <div>
                 <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={TrumpetP}
      alt="First slide"
    />
    </Jumbotron>
 


  
            </div>
        )
    }
}

    export default Trumpet