import React, { Component } from 'react'
import BlackPapiPacify from '../BlackPapiPacify.jpg'
import BrownPapiPacify from '../BrownPapiPacify.jpg'
import WhitePapiPacify from '../WhitePapiPacify.jpg'
import Carousel from 'react-bootstrap/Carousel'


export class PapiPacifyCarousel extends Component {
    render() {
        return (
            <div>
                
                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackPapiPacify}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhitePapiPacify}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownPapiPacify}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>


            </div>
        )
    }
}

export default PapiPacifyCarousel
