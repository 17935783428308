import React, { Component } from 'react'

export class SubscriptionButton extends Component {
    render() {
        return (
            <div style = {{display: 'flex' ,justifyContent: 'center'}}>
                <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
<input type="hidden" name="cmd" value="_s-xclick"/>
<input type="hidden" name="hosted_button_id" value="A6GLQ4LUNHU6G"/>
<table>
<tr><td><input type="hidden" name="on0" value="Attendees"/>Attendees</td></tr><tr><td><select name="os0">
	<option value="Individual">Individual $40.00 USD</option>
	<option value="Couple">Couple $80.00 USD</option>
</select> </td></tr>
</table>
<input type="hidden" name="currency_code" value="USD"/>
<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
</form>


            </div>
        )
    }
}

export default SubscriptionButton
