import React, {
	Component,
} from "react";

export class Stripe extends Component {
	render() {
		return (
			<div
				style={{
					display:
						"flex",
					justifyContent:
						"center",
					justifyContent:
						"space-evenly",
					flexWrap:
						"wrap",
					justifyContent:
						"space-around",
					marginBottom:
						"15%",
					marginTop:
						"10%",
				}}>
				<div className="rosemaryoil">
					<script
						async
						src="https://js.stripe.com/v3/buy-button.js"></script>

					<stripe-buy-button
						buy-button-id="buy_btn_1PwwLFLtyWpTzVZr4nqFLJqa"
						publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
				</div>

				<div className="rosemaryInfusion">
					<script
						async
						src="https://js.stripe.com/v3/buy-button.js"></script>

					<stripe-buy-button
						buy-button-id="buy_btn_1Pww0rLtyWpTzVZrc9gEI4ae"
						publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
				</div>

				<div className="flowerBombLotion">
					<script
						async
						src="https://js.stripe.com/v3/buy-button.js"></script>

					<stripe-buy-button
						buy-button-id="buy_btn_1Pww2eLtyWpTzVZrnhxXHTEZ"
						publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
				</div>

				<div className="toothpaste">
					<script
						async
						src="https://js.stripe.com/v3/buy-button.js"></script>

					<stripe-buy-button
						buy-button-id="buy_btn_1PwwDtLtyWpTzVZraesUUuEQ"
						publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
				</div>

				<div className="ointment">
					<script
						async
						src="https://js.stripe.com/v3/buy-button.js"></script>

					<stripe-buy-button
						buy-button-id="buy_btn_1PwwFoLtyWpTzVZrrfsyUyTe"
						publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
				</div>
			</div>
		);
	}
}

export default Stripe;
