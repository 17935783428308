import { render } from "@testing-library/react";
import { useEffect } from "react";

import { useRef } from "react";
import React  from 'react'
import "../../App.css";
import { ReactDOM} from "react";
import { Container } from "react-bootstrap";

function Widget(){

const containerRef = useRef(null)



useEffect(() => {
    if (window && containerRef.current) {

        window.cloudinary
        
        .galleryWidget({
            container: containerRef.current,
            cloudName:'morganart',

            aspectRatio:'16:9',
            bgColor:"transparent",
            transformation:{
                crop:""
            },
                
            // displayProps: {
            //     mode: "expanded",
            //     topOffset: 70 // to account for the menu element at the top of this documentation page
            //   },

            zoomProps:{
                type:"popup",
                steps:3,
                stepLimit: true,
                level: 1.3


            },
            imageBreakpoint: 150,
            mediaAssets:[

                { tag:'paracord'}
            ],
            carouselStyle:'indicators',
            carouselLocation: 'bottom',

        })
        .render();
    }
}, []);

return <Container  id='overlay' ><div  className="dialog" ref={containerRef} style={{color:'white',width:'auto', height:'auto', margin:"20%"}}/></Container>


}





export default Widget