import React, { Component } from 'react'
import PrideBlack from '../PrideBlack.jpeg'
import PrideBrown from '../PrideBrown.jpeg'
import Self from '../selflove.jpeg'
import Carousel from 'react-bootstrap/Carousel'
import { Jumbotron } from 'react-bootstrap'
export class PrideCarousel extends Component {
    render() {
        return (
            <div>
               
  <Jumbotron style = {{margin:"5%"}}>
    <img
      className="d-block w-100"
      src={Self}
      alt="First slide"
    />
    {/* <Jumbotron.Caption>
      
    </Jumbotron.Caption> */}
  </Jumbotron>
  {/* <Carousel.Item>
    <img
      className="d-block w-100"
      src={PrideBrown}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={PrideWhite}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item> */}
{/* </Carousel> */}

 
            </div>
        )
    }
}

export default PrideCarousel
