import React, {
	Component,
} from "react";
import SubscriptionButton from "./SubscriptionButton";
import paintparty from "../paintparty.jpg";
import Card from "react-bootstrap/Card";

export class PaintParty extends Component {
	render() {
		return (
			<div className="Home">
				<div
					style={{
						display:
							"flex",
						justifyContent:
							"center",
						marginBottom:
							"30%",
					}}>
					<Card
						style={{
							width:
								"25rem",
							height:
								"auto",
							opacity:
								"80%",
						}}>
						<Card.Img
							variant="top"
							src={
								paintparty
							}
							rounded
						/>
						<Card.Body>
							<Card.Title>
								Paint
								Party
							</Card.Title>
							<Card.Text>
								<div
									style={{
										marginBottom:
											"6%",
									}}>
									Bring
									yourself
									and
									I
									will
									provide
									all
									the
									supplies
									necessary
									for
									a
									Paint
									Party.
									After
									purchase
									submitt
									your
									suggestion
									for
									our
									subject.
									Be
									entered
									to
									have
									your
									suggestion
									selected
									for
									our
									evening
									of
									fun.
								</div>
								<SubscriptionButton />
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
			</div>
		);
	}
}

export default PaintParty;
