import React, { Component } from 'react'
import BlackUs from '../BlackUs.jpg'
import BrownUs from '../BrownUs.jpg'
import WhiteUs from '../WhiteUs.jpg'
import Carousel from 'react-bootstrap/Carousel'

export class BlackUsCarousel extends Component {
    render() {
        return (
            <div>
                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackUs}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteUs}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownUs}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>




            </div>
        )
    }
}

export default BlackUsCarousel
