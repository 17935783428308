import React, { Component } from "react";
import "../../App.css";
export class Stripe_Subscription extends Component {
  render() {
    return (
      <div className="Home"  style={{ display:"flex",justifyContent:"center", marginTop:"20%", marginBottom:"20%", scrollBehavior:"unset"}}>
       
       <stripe-buy-button
  buy-button-id="buy_btn_1OzLKlLtyWpTzVZrNFAqTlZU"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>

      </div>
    );
  }
}

export default Stripe_Subscription;

