import React, {
	Component,
} from "react";
import {
	Container,
	Jumbotron,
	Card,
} from "react-bootstrap";
import Loop from "./loops.jpg";
import Ring from "../../ring.jpg";
import "../../App.css";
export class FineSilver extends Component {
	render() {
		return (
			<div className="Home">
				<Container
					style={{
						display:
							"flex",
						justifyContent:
							"center",
						justifyContent:
							"space-around",
						marginBottom:
							"20%",
						flexWrap:
							"wrap",
					}}
					fluid>
					<div className="ring">
						<script
							async
							src="https://js.stripe.com/v3/buy-button.js"></script>

						<stripe-buy-button
							buy-button-id="buy_btn_1Pwq6kLtyWpTzVZrfoFuLhJA"
							publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
					</div>
					<div className="14">
						<script
							async
							src="https://js.stripe.com/v3/buy-button.js"></script>

						<stripe-buy-button
							buy-button-id="buy_btn_1PwxPrLtyWpTzVZr3bsv9Qux"
							publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
					</div>

					<div className="18">
						<script
							async
							src="https://js.stripe.com/v3/buy-button.js"></script>

						<stripe-buy-button
							buy-button-id="buy_btn_1PwxSDLtyWpTzVZrfjzrhtK1"
							publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
					</div>

					<div className="24">
						<script
							async
							src="https://js.stripe.com/v3/buy-button.js"></script>

						<stripe-buy-button
							buy-button-id="buy_btn_1PwxVjLtyWpTzVZr4OiorcI4"
							publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"></stripe-buy-button>
					</div>
				</Container>
			</div>
		);
	}
}

export default FineSilver;
