import React, { Component } from 'react'
import Dog from '../dog.jpg';
import BrownEveningSun from '../BrownEveningSun.jpg';
import WhiteEveningSun from '../WhiteEveningSun.jpg';
import Carousel from 'react-bootstrap/Carousel'
import { Jumbotron } from 'react-bootstrap';


export class EveningSunCarousel extends Component {
    render() {
        return (
            <div>
                 <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={Dog}
      alt="First slide"
    />
    </Jumbotron>
    {/* <Jumbotron.Caption>
      
    </Carousel.Caption> */}
  
  {/* <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteEveningSun}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownEveningSun}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item> */}



  
            </div>
        )
    }
}

export default EveningSunCarousel
