import React, { Component } from "react";

export class ButtonSeven extends Component {
  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <form
          target="paypal"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="N7BXZPYNKN7YA" />
          <table>
            <tr>
              <td>
                <input type="hidden" name="on0" value="Size" />
                Size
              </td>
            </tr>
            <tr>
              <td>
                <select name="os0">
                  <option value="11 x 14">11 x 14 $150.00 USD</option>
                  <option value="16 x 20">16 x 20 $200.00 USD</option>
                  <option value="18 x 24">18 x 24 $250.00 USD</option>
                </select>{" "}
              </td>
            </tr>
            <tr>
              <td>
                <input type="hidden" name="on1" value="Frame" />
                Frame
              </td>
            </tr>
            <tr>
              <td>
                <select name="os1">
                  <option value="Black">Black </option>
                  <option value="White">White </option>
                  <option value="Brown">Brown </option>
                </select>{" "}
              </td>
            </tr>
          </table>
          <input type="hidden" name="currency_code" value="USD" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
            border="0"
            name="submit"
            alt="PayPal - The safer, easier way to pay online!"
          />
          <img
            alt=""
            border="0"
            src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
    );
  }
}

export default ButtonSeven;
