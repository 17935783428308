import React, { Component } from 'react'
import BlackDrownin from '../BlackDrownin.jpg'
import BrownDrownin from '../BrownDrownin.jpg'
import WhiteDrownin from '../WhiteDrownin.jpg'
import Carousel from 'react-bootstrap/Carousel'


export class DrowninCarousel extends Component {
    render() {
        return (
            <div>
              
              <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackDrownin}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteDrownin}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownDrownin}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>


  
            </div>
        )
    }
}

export default DrowninCarousel
