import React, { Component } from 'react'
import ChurchHat from '../church.jpg';

import { Jumbotron } from 'react-bootstrap';


export class Church extends Component {
    render() {
        return (
            <div>
                 <Jumbotron  style = {{margin:"5%"}}>
  
    <img
      className="d-block w-100"
      src={ChurchHat}
      alt="First slide"
    />
    </Jumbotron>
 


  
            </div>
        )
    }
}

export default Church
