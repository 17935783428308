
import React, { useState, useEffect } from 'react';
import './Paracord.css';
import { img, Button } from 'bootstrap';

// import ParacordBracelets from './ParacordBracelets'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



import 'dotenv/config'
import { search, mapImageResources, getFolders} from '../../lib/cloudinary';
import { Container } from 'react-bootstrap';
import { act } from '@testing-library/react';
import ParacordBracelets from './ParacordBracelets';
const dotenv = require('dotenv')
dotenv.config()







export async function getStaticProps(){
  const results = await search({
expression: 'folder=""'
  });


const {resources, next_cursor: nextCursor} = results;
const images =mapImageResources(resources);
const {folders } = await getFolders();
return{
  props:{
    images,
    nextCursor: nextCursor || false,
    folders
  }
}


}




export default function Paracord({images: defaultImages, defaultNextCursor, folders}) {
    const [images, setImages] = useState(defaultImages);
    const [nextCursor, setNextCursor] = useState(defaultNextCursor);
    const [activeFolder, setActiveFolder] = useState('');

console.log("images paracord", images)
    // const history = useHistory()
    // const handleHistory = () => {
    //     history.push("/paracordBracelet")}
    


    // useEffect(() => {

    //   (async function run(){
        
    //     console.log('results', results)
    //   })()
    // }, [])
  
    // useEffect(() => {

    //   (async function run(){
    //     setData(search())
       
    //   })()
    // }, [])
    
async function handleLoadMore(event){

  event.preventDefault();
  const results = await fetch('/api/search', {
    method: 'POST',
    body: JSON.stringify({
      nextCursor,
      expression: `folder="${activeFolder}"`
    })
  }).then(response => response.json());

  const {resources, next_cursor: updatedNextCursor} = results;
  const images = mapImageResources(resources)

  setImages(prev => {
    return[
      ...prev,
      ...images
    ]
  });

  setNextCursor(updatedNextCursor)
  
}
   
function handleOnFolderClick(event){
const folderPath = event.target.dataset.folderPath;
setActiveFolder(folderPath);
setNextCursor(undefined);
setImages([])
}

  
    useEffect(() => {

      (async function run(){
        const results = await fetch('/api/search', {
          method: 'POST',
          body: JSON.stringify({
            nextCursor,
            expression: `folder="${activeFolder}"`
          })
        }).then(response => response.json());
      
        const {resources, next_cursor: updatedNextCursor} = results;
        const images = mapImageResources(resources)
      
        setImages(prev => {
          return[
            ...prev,
            ...images
          ]
        });
      
        setNextCursor(updatedNextCursor)
       
      })()
    }, [activeFolder])


    return (

     <Container>
<div>
<ul onClick = {handleOnFolderClick}>
 {folders && folders.map(folder => {

   return (


    <li key={folder.path}>
    <Button 
     style={{
      backgroundColor: "dodgerblue",
      border: "2px solid brown",
      borderRadius: "10%",
      color: "white",
      width: "100px",
      height: "45px"
    }}
    data-folder-path={folder.path}>{folder.name}</Button>
  </li>
   )
})}
</ul>
 

return (
<div>

{/* <Button onClick={handleHistory}>Paracord Bracelets</Button> */}

</div>


);

<ul>
{images && images.map(image => {
   return (
    <li key={image.id}>
    <a href={image.link} rel="noreferrer">
<div
  style={{
    bacgroundColor: "rgba(0, 0, 0,.03)",
    border: "solid 2px brown ",
    margin: "5%",
    width: "400px",
    height: "200px",
    marginLeft: "35%",
    backgroundBlendMode: "",
    opacity: "0.7"
  }}
>
<ParacordBracelets
style={{
  bacgroundColor: "rgba(0, 0, 0,.03)",
  border: "solid 2px brown ",
  margin: "5%",
  width: "400px",
  height: "200px",
  marginLeft: "35%",
  backgroundBlendMode: "",
  opacity: "0.7"
}}
width={image.width} height={image.height} image={image.image} />
</div>
<h3>
{image.title}
</h3>
  </a>
  </li>
   )
})}
</ul>


<Button
 style={{
  backgroundColor: "dodgerblue",
  border: "2px solid brown",
  borderRadius: "10%",
  color: "white",
  width: "100px",
  height: "45px"
}}
onclick={handleLoadMore}> LOAD MORE RESULTS </Button>
</div>
</Container>

    )
}



