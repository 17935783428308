import React, { Component } from 'react'
import Puerto from '../puerto-rico.jpg'
import BrownMagicalUs from '../BrownMagicalUs.jpg'
import WhiteMagicalUs from '../WhiteMagicalUs.jpg'
import Carousel from 'react-bootstrap/Carousel'
import { Jumbotron } from 'react-bootstrap'

export class MagicalUsCarousel extends Component {
    render() {
        return (
            <div>
                <Jumbotron style ={{margin: "5%"}}>
  {/* <Carousel.Item> */}
    <img
      className="d-block w-100"
      src={Puerto}
      alt="First slide"
    />
    {/* <Carousel.Caption>
      
    </Carousel.Caption> */}
  {/* </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteMagicalUs}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownMagicalUs}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item> */}
</Jumbotron>


            </div>
        )
    }
}

export default MagicalUsCarousel
