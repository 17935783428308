import React, { Component } from 'react'
import BlackDesperado from '../BlackDesperado.jpg'
import BrownDesperado from '../BrownDesperado.jpg'
import WhiteDesperado from '../WhiteDesperado.jpg'
import Carousel from 'react-bootstrap/Carousel'



export class DesperadoCarousel extends Component {
    render() {
        return (
            <div>
                

                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackDesperado}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhiteDesperado}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownDesperado}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>










            </div>
        )
    }
}

export default DesperadoCarousel
