import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Candido from "./Candido.jpg";
import Card from "react-bootstrap/Card";
import { Switch, Route } from "react-router-dom";
import Who from "./Blogs/Who";
import Nav from "react-bootstrap/Nav";
import "../../App.css"

export class Blog extends Component {
  render() {
    return (
      <Container >
        <Container className="Blogcontainer">
          <Card className=" BlogCard" style={{ width: "18rem", marginTop: "10%" }}>
            <Card.Img variant="top" src={Candido} />
            <Card.Body>
              <Card.Title>Lambda School Graduate</Card.Title>
              <Card.Text>
                I am now an official graduate of Lambda School....
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Nav.Link href="/Who">Read More</Nav.Link>
            </Card.Body>
          </Card>

          <Switch>
            <Route exact path="/Who" component={Who} />
          </Switch>
        </Container>
      </Container>
    );
  }
}

export default Blog;
