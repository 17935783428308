import React, { Component } from "react";
import "../App.css";
import Jumbotron from "react-bootstrap/Jumbotron";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Switch, Route } from "react-router-dom";
import Products from "./Products";
import Dialog from "./Dialog";
import BlackLady from "../BlackLady.jpg";
import ThumbelinaBlack from "../ThumbelinaBlack.jpeg";
import ThumbelinaWhite from "../ThumbelinaWhite.jpeg";
// var ReactCSSTransitionGroup = require('react-transition-group'); // ES5 with npm
// import ReactCSSTransitionGroup from 'react-transition-group'; // ES6

export class Home extends Component {
  render() {
    function off() {
      document.getElementById("overlay").style.display = "none";
    }

    return (
      <Container
        className="Home"
        style={{
          display: "flex"
        }}
      >
        <div onMouseOver={(e) => off(e)}>
          <div id="overlay">
            <Dialog />
          </div>
        </div>
        <div className="home_text" style={{}}>
          <Container
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "space-around",
              justifyContent: "space-evenly",
            }}
          >
            <Card
              className="CardOne"
              style={{
                opacity: "90%",
              }}
              width="auto"
              height="auto"
            >
              <Card.Body style={{ backgroundColor: "rgba(127, 0, 255, 0.8)"}}>
                <Card.Text style={{ color: "white", fontSize: "20px" }}>
                  Purchase Canvas, Heavy Mixed Media Paper Piece, or
                  Bring your canvas print to life with museum-quality ink (fade,
                  and water resistant).
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="CardTwo"
              style={{
                opacity: "90%",
               
               marginTop: "10%",
               marginBottom: "50%"
               
              }}
              height="auto"
            >
              <Card.Body style={{ backgroundColor: "rgba(127, 0, 255, 0.8)" }}>
                <Card.Text
                  className="CardTwoText"
                  style={{ color: "white", fontSize: "20px", flexWrap: "wrap" }}
                >
                  Our framed canvas prints are assembled, packaged, and shipped
                  by our expert framing staff and delivered "ready to hang" with
                  pre-attached hanging wire.
                </Card.Text>
                <Button
                  variant="warning"
                  href="/products"
                  style={{ color: "crimson", fontWeight: "bold" }}
                >
                  View Art
                </Button>
                {/* <Card.Link href="#">Another Link</Card.Link> */}
              </Card.Body>
            </Card >

            {/* <Image
              alt="Black and White Photo"
              src={BlackLady}
              className="a-block w-50"
              width="auto"
              height="auto"
              style={{
                position: "relative ",
                opacity: "0%",
                justifyContent: "space-around",
              }}
              fluid
            /> */}
            {/* <Image
              alt="Black and White Photo"
              src={BlackLady}
              className="a-block w-25 "
              width="auto"
              height="auto"
              style={{
                position: "relative ",
                opacity: "100%",
                justifyContent: "space-around",
              }}
              fluid
            />
            <Image
              alt="Black and White Photo"
              src={ThumbelinaBlack}
              className="a-block h-25 "
              width="auto"
              height="auto"
              style={{
                position: "relative ",
                opacity: "100%",
                justifyContent: "space-around",
              }}
              fluid
            /> */}


            
          </Container>

          <Switch>
            <Route path="/products" component={Products} />
          </Switch>
        </div>

      </Container>
    );
  }
}

export default Home;
