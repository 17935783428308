import React, { Component } from "react";
import Container from "react-bootstrap/esm/Container";
import "./Notification.css";

export default class Notification extends Component {
  render() {
    return (
      <Container fluid>
        <div
          className={`notification ${this.props.level} ${
            this.props.visible ? "visible" : " "
          }`}
        >
          <img src={this.props.src} alt={this.props.alt} />
          <p>{this.props.message}</p>
        </div>
      </Container>
    );
  }
}
