import React, { Component } from 'react'

export class ChurchButton extends Component {
    render() {
        return (
            <div style = {{display: 'flex' ,justifyContent: 'center'}}>
                

                

              

<stripe-buy-button
  buy-button-id="buy_btn_1OzTLvLtyWpTzVZrWUyDSxTj"
  publishable-key="pk_live_51OufQ5LtyWpTzVZr8n6kSbefb2lMScXVk89wC5g4ZTMTqHatpWRcFXj4TqVi5uryvghdyjosCLV4jaHnXsaqD1Em00tQr8ErtL"
>
</stripe-buy-button>
            </div>
        )
    }
}

export default ChurchButton
