import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import ThunmbelinaBlack from "../ThumbelinaBlack.jpeg";
import ThumbleinaBrown from "../ThumbelinaBrown.jpeg";
import ThumbelinaWhite from "../ThumbelinaWhite.jpeg";
export class ThumbelinaCarousel extends Component {
  render() {
    return (
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={ThunmbelinaBlack}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={ThumbelinaWhite}
              alt="Second slide"
            />

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={ThumbleinaBrown}
              alt="Third slide"
            />

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default ThumbelinaCarousel;
