import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import "../Blog.css";

import "../../../App.css"

export class Who extends Component {
  render() {
    return (
      <Container className="BlogContainer">
        <Card style={{ marginTop: "30%" }}>
          <Card.Body
            style={{
              fontWeight: "bolder",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Official Lambda School Graduate
          </Card.Body>
        </Card>
        <Container
          style={{
            display: "flex",
            justifyContent: "space-around",
            juatifyContent: "space-between",
            marginTop: "10%",
          }}
          width="auto"
          height="auto"
          fluid
        >
          {/* <Image
            className="Candido"
            src={Candido}
            rounded
            style={{ height: "500px", width: "687px" }}
            fluid
          /> */}

          <Card id="Candido" className="Candido" style={{  backgroundColor:"black",height:"100%", width: "200rem" }}>
            <Card.Body className="CandidoBody" width="auto" height="auto" fluid>
              <Card.Text id="CandidoText" className="Candidotext" width="auto" height="auto"  style={{ backgroundColor:"black", color:"white", fontSize:"30px", margin:"10%"}} fluid>
                I am now an official graduate of Lambda School. An intensive
                coding school where I studied full stack web development and
                computer science fundamentals. In 2017 I relocated from NC to
                NYC where I attended Pacific College of Oriental Medicine. While
                there I studied Massage Therapy. Shortly after my first year I
                realized that although I'm a wonderful healer, massage school
                was not the next level for me to make my upgrade. While talking
                about my next move in a conversation I found out about a coding
                school named Lambda School. I was especially drawn to Lambda
                School by it's unique model of providing an education and paying
                after you have your dream job. I was a little nervous at first
                about applying but I remembered enjoying the computer science
                classes I took during my Undergraduate studies at Fordham
                University and continued education courses at Kean Univerisity.
                It had been such a long time since I had taken those courses,
                that the pre-course work for web development looked like a
                foreign language. Despite my reservations and the unfamiliar
                territory, in February 2019 I embarked on a journey that has
                been one of the most rewarding yet difficult to date. Now almost
                three years later after learning JavaScript, React, and Python
                amongst other tools and technologies, I can say I have reached
                the finish line of a Marathon. But I am not finished with my
                education. I strive to learn new technologies, become a better
                front-end designer, and write clean code. Lambda School Taught
                Me: How to learn new technology by researching and learning the
                art of reading docs. The importance of communicating with my
                team when working in a remote environment. The joys of
                participating in the community and supporting my classmates by
                providing feedback or pair programming when necessary to debug.
                Real discipline, focus, drive and persistence. I volunteered to
                be a mentor because giving back to the community that supported
                me through this journey is important. The Team Leads that took
                the time to answer the questions I worked up the nerve to ask
                and who stayed up late to step through code with me have been
                instrumental in my success at Lambda School. In conclusion Im
                looking forward to continuing growing as a Web Developer. Honing
                my software craftsmanship skills. Working in collaboration with
                other amazing developers, artists, influencers, and creators!
                TTYL, MDP{" "}
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </Container>
    );
  }
}

export default Who;
