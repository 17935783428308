import React from "react";
import Card from "react-bootstrap/Card";
import Artwork from "../Dialogs_Artwork.jpg";
import "../App.css";
import Container from "react-bootstrap/esm/Container";

class Dialog extends React.Component {
	render() {
		const {
			onClose,
		} =
			this
				.props;
		return (
			//    key={key}
			// appear={true}
			// onEnter={(node, appears) => play(pathname, node, appears)}
			// timeout={{enter: 750, exit: 0}}

			<div
				className="dialog--overlay"
				onClick={
					onClose
				}>
				<div className="dialog">
					<Card
						style={{
							width:
								"auto",
							height:
								"fluid",
							backgroundColor:
								"papayawhip",
						}}
						fluid>
						<Container>
							<Card.Body>
								<Card.Title></Card.Title>
								{/* <Card.Text>
      <div style={{marginBottom:"6%"}}>
      Bring yourself and I will provide all the supplies necessary for a Paint Party. After purchase submitt your suggestion 
      for our subject. Be entered to have your suggestion selected for our evening of fun.
    </div>
    </Card.Text> */}
								<Card.Img
									variant="top"
									src={
										Artwork
									}
									fluid
								/>
							</Card.Body>
						</Container>
					</Card>
				</div>
			</div>
		);
	}
}

export default Dialog;
