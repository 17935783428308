import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IMG_0256 from "../IMG_0256.jpeg";
import PayPalButton from "./PayPalButton";
import ButtonTwo from "./ButtonTwo";
import ButtonThree from "./ButtonThree";
import ButtonFour from "./ButtonFour";
import ButtonFive from "./ButtonFive";
import ButtonSix from "./ButtonSix";
import ButtonSeven from "./ButtonSeven";
import ButtonEight from "./ButtonEight";
import MagicalUsButton from "./MagicalUsButton";
import Card from "react-bootstrap/Card";
import EveningSunButton from "../Carousel/EveningSunButton";
import EveningSunCarousel from "../Carousel/EveningSunCarousel";
import MagicalUsCarousel from "../Carousel/MagicalUsCarousel";
import MagicalCarousel from "../Carousel/MagicalCarousel";
import PurleHazeCarousel from "../Carousel/PurleHazeCarousel";
import DrowninCarousel from "../Carousel/DrowninCarousel";
import LadyCarousel from "../Carousel/LadyCarousel";
import PapiPacifyCarousel from "../Carousel/PapiPacifyCarousel";
import BlackUsCarousel from "../Carousel/BlackUsCarousel";
import DesperadoCarousel from "../Carousel/DesperadoCarousel";
import PrideButton from "./PrideButton";
import PrideCarousel from "../Carousel/PrideCarousel";
import ThumbelinaCarousel from "../Carousel/ThumbelinaCarousel";
import ThumbelinaButton from "./ThumbelinaButton";
import Button from "react-bootstrap/esm/Button";
import Jumbotron from "react-bootstrap/esm/Jumbotron";
import { Church } from "../Carousel/Church";
import ChurchButton from "../Carousel/ChurchButton";
import Tribal from "../Carousel/Tribal";
import TribalButton from "../Carousel/TribalButton";
import UnchainedButton from "../Carousel/UnchainedButton";
import Unchained from "../Carousel/Unchained";
import Trumpet from "../Carousel/Trumpet";
import TrumpetButton from "../Carousel/TrumpetButton";

export class Products extends Component {
  render() {
    return (
      <div className="Home">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // eslint-disable-next-line
            justifyContent: "space-around",
            alignContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <PrideCarousel />
              </div>
            </div>
            <Card.Body>
              <Card.Title>Self Love</Card.Title>
              <h5>Size</h5>
               <h6>30" x 40" $1099.99</h6>
              
              <Card.Text>
                <PrideButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <EveningSunCarousel />
            <Card.Body>
              <Card.Title>Dog Hat</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500</h6>
              <Card.Text>
                <EveningSunButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <MagicalUsCarousel />
            <Card.Body>
              <Card.Title>Puerto Rico</Card.Title>
              <h5>Size</h5>
               <h6>25" x 34" $1099.99</h6>
              <Card.Text>
                <MagicalUsButton />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Church />
            <Card.Body>
              <Card.Title>Church Hat</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500.99</h6>
              <Card.Text>
                <ChurchButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Tribal/>
            <Card.Body>
              <Card.Title>Tribal</Card.Title>
              <h5>Size</h5>
               <h6>20" x 24" $500.99</h6>
              <Card.Text>
                <TribalButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Unchained/>
            <Card.Body>
              <Card.Title>Unchained Melody</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $299.99</h6>
              <Card.Text>
                <UnchainedButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Trumpet/>
            <Card.Body>
              <Card.Title>Trumpet Lady</Card.Title>
              <h5>Size</h5>
               <h6>18" x 24" $299.99</h6>
              <Card.Text>
                <TrumpetButton/>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <MagicalCarousel />
            <Card.Body>
              <Card.Title>Magical</Card.Title>
              <Card.Text>
                <PayPalButton />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            {/* <Card.Img variant="top" src={IMG_0197} rounded/> */}
            <PurleHazeCarousel />
            <Card.Body>
              <Card.Title>Purle Haze</Card.Title>
              <Card.Text>
                <ButtonTwo />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            {/* <Card.Img variant="top" src={IMG_0244} rounded/> */}
            <DrowninCarousel />
            <Card.Body>
              <Card.Title>Drownin</Card.Title>
              <Card.Text>
                <ButtonThree />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <Card.Img variant="top" src={IMG_0256} rounded />
            <Card.Body>
              <Card.Title>Breath</Card.Title>
              <Card.Text>
                <ButtonFour />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <LadyCarousel />
            <Card.Body>
              <Card.Title>Lady</Card.Title>
              <Card.Text>
                <ButtonFive />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <PapiPacifyCarousel />
            <Card.Body>
              <Card.Title>Papi Pacify</Card.Title>
              <Card.Text>
                <ButtonSix />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <BlackUsCarousel />
            <Card.Body>
              <Card.Title>Pa Ti Papi</Card.Title>
              <Card.Text>
                <ButtonSeven />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <DesperadoCarousel />
            <Card.Body>
              <Card.Title>Desperado</Card.Title>
              <Card.Text>
                <ButtonEight />
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: "25rem", height: "auto", marginBottom: "5%" }}>
            <ThumbelinaCarousel />
            <Card.Body>
              <Card.Title>Black Thumbelina</Card.Title>
              <Card.Text>
                <ThumbelinaButton />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default Products;
