import React, { Component } from 'react'
import BlackPurleHaze from '../BlackPurleHaze.jpg'
import BrownPurleHaze from '../BrownPurleHaze.jpg'
import WhitePurleHaze from '../WhitePurleHaze.jpg'
import Carousel from 'react-bootstrap/Carousel'


export class PurleHazeCarousel extends Component {
    render() {
        return (
            <div>
                <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BlackPurleHaze}
      alt="First slide"
    />
    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={WhitePurleHaze}
      alt="Second slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={BrownPurleHaze}
      alt="Third slide"
    />

    <Carousel.Caption>
      
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>

            </div>
        )
    }
}

export default PurleHazeCarousel
